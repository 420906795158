/*
 *  Box hover effect
 * -----------------------------------------------
*/
.box-hover-effect {
	position: relative;
	overflow: hidden;
	margin: 0;
	width: 100%;

	img {
		position: relative;
		display: block;
		max-height: 100%;
		max-width: 100%;
		opacity: 1;
		.transition(all .3s ease 0s);
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		.backface-visibility(hidden);
		.transition(all .3s ease 0s);

		&::before,
		&::after {
			pointer-events: none;
		}

		.details {
			.transition(all .3s ease 0s);
		}

		.title {
			.transition(all .3s ease 0s);
		}

		.para {
			.transition(all .3s ease 0s);
		}

		.entry-meta {
			bottom: 10px;
			font-size: 70%;
			opacity: 0;
			position: absolute;
			width: 89%;
			.transition(all .3s ease 0s);

			&.top {
				top: 10px;
			}

			i {
				color: @white-base;
			}
		}

		.icons {
			left: 0;
			opacity: 0;
			position: absolute;
			text-align: center;
			top: 40%;
			width: 100%;
			.transition(all .3s ease 0s);
		}
	}

	&:hover {
		.overlay {
			background: fade(@black, 85%);

			&.white {
				background: fade(@white-base, 75%);
			}

			&.black {
				background: fade(@black, 85%);
			}

			.entry-meta {
				opacity: 1;
			}

			.icons {
				opacity: 1;

				i {
					color: @white-base;
				}

				&.twin {
					i {
						padding: 10px;
					}
				}

				&.bordered {
					i {
						border: 1px solid @white-base;

						&.text-black {
							border-color: @black;
						}
					}
				}
			}
		}
	}
}

.shadow-overlay {
	background-image: url("../images/shadow-overlay.png");
	background-position: center bottom;
	background-repeat: repeat-x;
	bottom: 0;
	color: @white-base;
	height: 100%;
	left: 0;
	position: absolute;
	width: 100%;
}

/* Individual effects */

/*---------------*/
/***** effect1 *****/
/*---------------*/
.box-hover-effect{
	&.effect1 {
		.overlay {
			position: relative;
		}

		.thumb {
			position: relative;
			overflow: hidden;

			&:after {
				  background-color: fade(@white-base, 15%);
				  bottom: 0;
				  content: "";
				  height: 0;
				  position: absolute;
				  right: 0;				  
				  .transition(all 0.3s ease-out 0s);
				  width: 0;
			}

			&:before {
				// background-color: fade(@white-base, 15%);
				  left: 0;
				  content: "";
				  height: 0;
				  position: absolute;
				  top: 0;
				  .transition(all 0.3s ease-out 0s);
				  width: 0;
				  z-index: @zindex-box-hover-effect-effect1-thumb-before;
			}
		}

		&:hover {
			.thumb {
				&:before,
				&:after {
					height: 100%;
					width: 100%;
				    .transition(all 0.4s ease-out 0s);
				}
			}
		}
	}
}

/*---------------*/
/***** effect2 *****/
/*---------------*/
.box-hover-effect {
	&.effect2 {
		.thumb {
			position: relative;
			overflow: hidden;
		}

		.overlay {
			.overlay-details {
				opacity: 0;
				.scale(0.8, 0.8);
				.transition(all 0.5s ease);
			}
		}

		&:hover {
			.overlay-details {
				opacity: 1;
				.scale(1, 1);
			}

			.details {
				opacity: 0;
			}

			img {
				.scale(2);
				.transition(all 0.5s ease);
			}
		}
	}
}

/*Custom theme style*/
.box-hover-effect {
	&.effect2 {
		.overlay {
			&.white {
				.overlay-details {
					p {
						color: @gray-light;
					}
				}

			}
		}
	}
}
.thumb {
	overflow: hidden;
}
.thumb.zoom-effect img{
	.transition(all 0.5s ease);
}
.thumb.zoom-effect img:hover {
	.scale(1.1);
}

/*border-effect*/
.border-effect {
	position: relative;
}
.border-effect::after {
  border: 3px solid #abc502;
  content: "";
  height: 94%;
  left: 2.3%;
  position: absolute;
  right: 0;
  top: 3%;
  transform: scale(1.4);
  transition: all 500ms ease 0s;
  width: 96%;
}
.border-effect:hover::after {
  transform: scale(1);
}
.border-effect img {
  transition: all 700ms ease 0s;
}
.border-effect:hover img {
  transform: scale(1.18) rotate(5deg);
}