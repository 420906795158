@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        epetcare
Version:        1.0
Last change:    03.29.2016
Primary use:    Pet Care, Pets Club, Veterinary & Shop HTML5 Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/

// Table of contents
@import "less-petcare/table-of-content.less";

// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";


//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
/* @import "../css/elegant-icons.css"; */
/* @import "../css/stroke-gap-icons.css"; */
@import "../css/flaticon-set-pet.css";
@import "../css/utility-classes.css";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800|Raleway:400,300,200,500,700,600,800|Roboto+Slab:400,700,300,100);


 
// Initialize Variables
@import "less-petcare/variables.less";
@import "less-petcare/mixins.less";
// Typography
@import "less-petcare/typography.less";

// Common Styles
@import "less-petcare/common.less";
@import "less-petcare/extra.less";
@import "less-petcare/overlay.less";

// Header
@import "less-petcare/header.less";

// Nav
@import "less-petcare/nav.less";

// Content Blocks
@import "less-petcare/topbar.less";
@import "less-petcare/inner-header-title.less";
@import "less-petcare/vertical-nav.less";
@import "less-petcare/menu-full-page.less";
@import "less-petcare/boxed-layout.less";
@import "less-petcare/form.less";
@import "less-petcare/side-push-panel.less";
@import "less-petcare/box-hover-effect.less";
@import "less-petcare/gallery-isotope.less";
@import "less-petcare/sliders.less";
@import "less-petcare/home.less";
@import "less-petcare/about.less";
@import "less-petcare/contact.less";
@import "less-petcare/event.less";
@import "less-petcare/responsive.less";
@import "less-petcare/services.less";
@import "less-petcare/shop.less";
@import "less-petcare/classes-details.less";
@import "less-petcare/blog.less";
@import "less-petcare/funfact.less";
@import "less-petcare/trainer.less";
@import "less-petcare/traning-class.less";
@import "less-petcare/calculator.less";
@import "less-petcare/why-choose.less";
@import "less-petcare/traning-class.less";
@import "less-petcare/job.less";
@import "less-petcare/work-gallery.less";



// Shortcodes
@import "less-petcare/shortcodes.less";


// Widgets
@import "less-petcare/widgets.less";


// Footer
@import "less-petcare/footer.less";