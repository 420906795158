/*
 * Shortcode: Pricing
 * -----------------------------------------------
*/
.pricing-table {
	margin-bottom: 30px;
	position: relative;
	.table-price {
		color: @black-111;
		font-size: 45px;

		span {
			font-size: 13px;
			vertical-align: middle;
		}
	}

	&.featured {
		border: 1px solid @black-111;
	}

	.table-type {
		display: inline;
	}

	.btn-signup {
		margin-right: 0;
		width: 100%;
	}

	.table-list {
		list-style: outside none none;
		padding: 10px 0 10px 20px;
		background: @white-f8;

		&.list { 
			&.check-circle {
				li {
					padding: 5px 0 5px 30px;
					&:before {
						content: "";
					    font-family: fontawesome;
					    font-size: 20px;
					    vertical-align: middle;
					}
				}
			}
		}

		li {
			border-bottom: 1px solid #ededed;
			padding: 5px 0;
			i {
			  	margin-right: 8px;
			}
		}
	}

	.price-amount {
		width: 80px;
		height: 80px;
		margin-top: -73px;
		margin-left: 20px;
		margin-right: 20px;
		line-height: 80px;
		font-size: 2rem;
	}
}
